

class Select {
  constructor($el) {
    this.$el = $el;
    this.isOpen = false;
    this.$toggle = this.$el.find('.BtnSelect');
    this.$options = this.$el.find('.SelectOptions');

    this.$toggle.on('click', () => {
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });
  }
  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      this.$el.addClass('-open');
      $('body').addClass('open-category');
      this.$options.css('display', 'block');
    }
  }
  close() {
    if(this.isOpen) {
      this.isOpen = false;
      this.$el.removeClass('-open');
      $('body').removeClass('open-category');
      this.$options.css('display', 'none');
    }

  }
}

export default Select;
