/*globals $ */

class FirstviewColorChanger {
  constructor() {
    // console.log('FirstviewColorChanger');
    this.isChange = false;

    this.update = setInterval(() => {
      this.toggle();
    }, 6000);
  }

  toggle() {
    if(this.isChange) {
      this.isChange = false;
      $('.Firstview').removeClass('Firstview--colorfade-change');
      $('.News__title').children('.SvgImg').removeClass('SvgImg--fill-white');
      $('.Logo__white').css('opacity', 0);
    } else {
      this.isChange = true;
      $('.Firstview').addClass('Firstview--colorfade-change');
      $('.News__title').children('.SvgImg').addClass('SvgImg--fill-white');
      $('.Logo__white').css('opacity', 1);
    }
  }
}
export default FirstviewColorChanger;
