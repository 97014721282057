/*globals $ */

// import InViewObj from './InViewObj';
// import PerfectScrollbar from 'perfect-scrollbar';

class SubMenu {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.isFadein = false;

    this.$container_pc = $('.SubMenuPc');
    this.$container_sp = $('.SubMenuSp');

    /*
    $('.js-toggle-submenu').on('click', () => {
      if(this.isFadein) {
        this.close();
      } else {
        this.open();
      }
    });
    */
  }
  offTouchEvent(e) {
    e.preventDefault();
  }

  open() {
    if (!this.isFadein) {
      $('.js-toggle-submenu').addClass('-fadein');
      this.isFadein = true;

      if (window.matchMedia('(max-width: 750px)').matches) {
        this.$container_sp.addClass('-fadein');
        this.$container_sp
          .stop(false)
          .animate(
            { height: $('.SubMenuSp__inner').outerHeight() },
            400,
            'easeOutExpo'
          );
      } else {
        this.$container_pc.addClass('-fadein');
      }
    }
  }
  close() {
    if (this.isFadein) {
      this.isFadein = false;
      $('.js-toggle-submenu').removeClass('-fadein');

      if (window.matchMedia('(max-width: 750px)').matches) {
        this.$container_sp.removeClass('-fadein');
        this.$container_sp
          .stop(false)
          .animate({ height: 0 }, 400, 'easeOutExpo', () => {
            const container = document.querySelector('#MenuScroller');
            container.scrollTop = 0;
            this.mainApp.menu.ps.update();
          });
      } else {
        this.$container_pc.removeClass('-fadein');
      }
    }
  }
}

export default SubMenu;
