class SvgImg {
  constructor($container) {
    this.elements = [];
    this.svg = $container.find('svg');

    this.svg_root = this.svg.children('g');
    this.svg_root.children().each((i, e) => {
      this.elements.push($(e));
    });

    for(let i = 0; i < this.elements.length; i++) {
      this.elements[i].css('opacity', 0);
    }

  }
  fadein() {
    if(!this.isFadein && this.elements.length) {
      this.isFadein = true;
      this.fadeinCount = 0;

      this.fadeinInterval = setInterval(() => {
        this.elements[this.fadeinCount].css('opacity', 1);
        this.fadeinCount++;

        if(this.fadeinCount == this.elements.length) {
          clearInterval(this.fadeinInterval);
        }
      }, 60);
    }

  }
  fadeout() {
    if(this.isFadein) {
      this.isFadein = false;
      clearInterval(this.fadeinInterval);

      for(let i = 0; i < this.elements.length; i++) {
        this.elements[i].css('opacity', 0);
      }
    }
  }
}

export default SvgImg;
