/*globals $ */

import InViewObj from './InViewObj';

class ScrollSectionContents {
  constructor($el) {
    this.$container = $el;
    this.fadeinObjects = [];

    this.$container.find('.InViewObj').each((i, e) => {
      this.fadeinObjects.push(new InViewObj($(e)));
    });
  }

  fadein() {
    clearInterval(this.fadeinTimeout);
    clearInterval(this.fadeinInterval);
    /*
    this.$container.css({
      'opacity': 1,
    });
    */
    if(this.fadeinObjects.length > 0) {
      this.fadeinTimeout = setTimeout(() => {
        clearInterval(this.fadeinInterval);
        this.fadeinCount = 0;
        this.fadeinInterval = setInterval(() => {
          this.fadeinObjects[this.fadeinCount].fadein();
          this.fadeinCount++;

          if(this.fadeinCount == this.fadeinObjects.length) {
            clearInterval(this.fadeinInterval);
          }
        }, 60);
      }, 50);
    }
  }
  fadeout() {
    clearInterval(this.fadeinTimeout);
    clearInterval(this.fadeinInterval);
    /*
    this.$container.css({
      'opacity': 0,
    });
    */
    if(this.fadeinObjects.length > 0) {
      for(let i = 0; i < this.fadeinObjects.length; i++) {
        this.fadeinObjects[i].fadeout();
      }
    }
  }
}

class Pager {
  constructor(section, $container) {
    this.sectionApp = section;
    this.$container = $container;
    this.pages = [];
    this.$container.find('.SectionPagerItem').each((i, e) => {
      this.pages.push($(e));
    });

    this.$container.find('.SectionPagerItem').on('click', (e) => {
      this.sectionApp.pageChange(parseInt($(e.currentTarget).attr('data-target')));

    });
  }
  fadein() {
    this.$container.addClass('SectionPager--show');
  }
  fadeout() {
    this.$container.removeClass('SectionPager--show');
  }
  pageChange(target) {
    for(let i = 0; i < this.pages.length; i++) {
      if(i == target) {
        this.pages[i].css('display', 'flex');
      } else {
        this.pages[i].css('display', 'none');
      }
    }
  }
}

class ScrollSection {

  constructor(pageApp, $container) {
    this.pageApp            = pageApp;
    this.$container         = $container;
    this.id                 = this.$container.attr('data-id');
    this.isFadein           = false;
    this.isFooter           = false;
    this.fadeinObjects      = [];
    this.isScrollContents   = false;

    this.$layout = this.$container.find('.Layout');

    this.$window            = this.$container.find('.ScrollSectionWindow');
    this.contentsSet = [];
    this.currentContentsIndex = 0;
    this.$container.find('.ScrollSectionContents').each((i, e) => {
      this.contentsSet.push(new ScrollSectionContents($(e)));
    });

    if(this.$container.find('.SectionPager').length) {
      this.pager = new Pager(this, this.$container.find('.SectionPager'));
    }

    this.currentContents = this.contentsSet[this.currentContentsIndex];

    if(this.id == 'footer') {
      this.isFooter = true;
    }
  }

  fadein() {

    if(!this.isFadein) {
      // console.log('Fadein Section -> ' + this.id);

      if(this.contentsSet.length > 1) {
        this.pager.fadein();
      }

      this.isFadein = true;
      $('.Background').attr('data-bg', this.$container.attr('data-bg'));
      if(this.contentsSet.length > 1) {
        this.pageApp.sectionTitle.fadein(this.currentContents.$container.attr('data-replace-id'));
      } else {
        this.pageApp.sectionTitle.fadein(this.id);
      }

      this.$window.css({
        'z-index':1,
        'pointer-events': 'auto'
      });

      this.currentContents.fadein();
    }
  }

  fadeout() {
    if(this.isFadein) {
      // console.log('Fadeout Section -> ' + this.id);

      if(this.contentsSet.length > 1) {
        this.pager.fadeout();
        // this.$pager.removeClass('SectionPager--show');
      }

      this.isFadein = false;
      this.$window.css({
        'z-index': 0,
        'pointer-events': 'none'
      });

      this.currentContents.fadeout();

    }
  }
  pageChange(target) {
    this.currentContentsIndex = target;
    for(let i = 0; i < this.contentsSet.length; i++) {
      if(i == this.currentContentsIndex) {
        this.currentContents = this.contentsSet[i];
        this.contentsSet[i].$container.css('display', 'block');
      } else {
        this.contentsSet[i].fadeout();
        this.contentsSet[i].$container.css('display', 'none');
      }
    }
    this.pager.pageChange(this.currentContentsIndex);

    this.onWindowResize();
    this.pageApp.mainApp.scrollPage(Math.floor(this.$container.offset()['top'] + window.innerHeight / 2), true);
    this.currentContents.fadein();
    this.pageApp.sectionTitle.fadein(this.currentContents.$container.attr('data-replace-id'));


  }

  onWindowResize() {
    if(this.isFooter) {
      this.isScrollContents = true;
      this.$container.addClass('ScrollSection--isScrollContents');
      this.$container.css('height', 'auto');
    } else {
      let limitHeight = this.pageApp.mainApp.minWindowHeight - $('.LogoTq').height() - 40;

      this.isScrollContents = true;
        this.$container.addClass('ScrollSection--isScrollContents');
        this.$container.css('height', 'auto');

        if(window.matchMedia('(max-width: 750px)').matches) {
          this.$layout.css('padding-top', 120);
        } else {
          this.$layout.css('padding-top', $('.LogoTq').height());
        }

      /*
      if(this.$container.find('.Layout__contents').height() > limitHeight) {
        this.isScrollContents = true;
        this.$container.addClass('ScrollSection--isScrollContents');
        this.$container.css('height', 'auto');

        if(window.matchMedia('(max-width: 750px)').matches) {
          this.$layout.css('padding-top', 120);
        } else {
          this.$layout.css('padding-top', $('.LogoTq').height());
        }

      } else {
        let layoutHeight = this.$container.find('.Layout__contents').height();
        let checkLogoValue = Math.floor((this.pageApp.mainApp.minWindowHeight - layoutHeight) / 2);
        let logoHeight = $('.LogoTq').height();

        if(window.matchMedia('(max-width: 750px)').matches) {
          console.log('chkecklspsppsp');
          logoHeight = 120;
        }

        this.isScrollContents = false;
        this.$container.removeClass('ScrollSection--isScrollContents');
        this.$container.css('height', window.innerHeight);
        let checkVal = Math.floor((limitHeight - this.$container.find('.Layout__contents').height()) / 3);

        if(checkLogoValue < logoHeight) {
          this.$layout.css('padding-top', logoHeight);
        } else {
          this.$layout.css('padding-top', checkVal);
        }

      }
      */

    }
  }
}

export default ScrollSection;
